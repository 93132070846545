<template>
  <el-drawer title="用户组" :visible.sync="visible" direction="rtl" size="40%">
    <div style="padding: 0 10px">
      <el-table :data="data" v-loading="loading" style="width: 100%">
        <el-table-column prop="CcusName" label="客户名称"> </el-table-column>
        <el-table-column prop="CcusCode" label="客户编号"></el-table-column>
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <div style="height: 60px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button @click="visible = false">取消</el-button>
    </div>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      GroupId: "",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      data: [],
    };
  },
  methods: {
    open(id) {
      this.data = [];
      this.GroupId = id;
      this.visible = true;
      this.pagination.current = 1;
      this.getDataList();
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.$http
        .post("BO/CustomerGroup/GetCusGrouped", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: {
            GroupId: this.GroupId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.Success) {
            this.data = res.Data;
            if (this.data.length > 0) {
              const pagination = { ...this.pagination };
              pagination.total = res.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
};
</script>

<style>
</style>